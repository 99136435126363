"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Whychoose = () => {
  return (
    <div>
      <section className="bg-blue-950 py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-16">
            <h2 className="font-bold text-yellow-400 uppercase my-3">
              Why Choose Us
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between text-white">
            <div>
              <div className="mb-6 md:mb-8">
                <h5 className="font-sans font-bold text-yellow-400 mb-2">
                  Personalized Readings
                </h5>
                <p className="font-semibold text-lg md:text-xl">
                  Our services are tailored to your unique life circumstances,
                  offering personalized and relevant guidance.
                </p>
              </div>
              <div className="mb-6 md:mb-8">
                <h5 className="font-sans font-bold text-yellow-400 mb-2">
                  Blend of Tradition and Modern Insights
                </h5>
                <p className="font-semibold text-lg md:text-xl">
                  We combine traditional Vedic wisdom with modern perspectives
                  for actionable advice in today’s world.
                </p>
              </div>
              <div className="mb-6 md:mb-8">
                <h5 className="font-sans font-bold text-yellow-400 mb-2">
                  Proven Success
                </h5>
                <p className="font-semibold text-lg md:text-xl">
                  Thousands of satisfied clients worldwide have benefited from
                  our life-changing astrological insights.
                </p>
              </div>
              <div className="mb-6 md:mb-8">
                <h5 className="font-sans font-bold text-yellow-400 mb-2">
                  Comprehensive Solutions:
                </h5>
                <p className="font-semibold text-lg md:text-xl">
                  We guide on relationships, career, health, personal growth,
                  and more, providing clarity.
                </p>
              </div>
              <div className="mb-6 md:mb-8">
                <h5 className="font-sans font-bold text-yellow-400 mb-2">
                  Remedies:
                </h5>
                <p className="font-semibold text-lg md:text-xl">
                  We sugest home made remedies for all planets to give good
                  results and make fruitful on life activites.
                </p>
              </div>
            </div>
            <div>
              <Image
                src="/images/weekly-horoscope-prediction.jpg"
                className="object-cover m-auto mb-2"
                alt="logo"
                width={675}
                height={675}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whychoose;
