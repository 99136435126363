"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Whatsinclude = () => {
  return (
    <div>
      <section className="bg-white py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-16">
            <h2 className="font-bold text-black uppercase my-3">
              What is Included in Our Services
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-start justify-between">
            <div>
              <h5 className="font-bold text-black mb-5">
                Vedic Astrological Horoscopes
              </h5>
              <p>
                Detailed analysis of your birth chart based on
                <strong>vedic astrology horoscope prediction</strong>, and{" "}
                <strong>indian vedic astrology horoscope</strong>.
              </p>
              <p>
                Insights into career, relationships, health, and spiritual
                growth.
              </p>
              <p>
                Personalized guidance on key life events, challenges, and
                opportunities.
              </p>
            </div>
            <div>
              <h5 className="font-bold text-black mb-5">
                Marriage Compatibility
              </h5>
              <p>
                Comprehensive comparison of two horoscopes for emotional and
                mental compatibility, focusing on
                <strong> birthdate compatibility for marriage</strong>, and{" "}
                <strong> marriage compatibility by nakshatra</strong>.
              </p>
              <p>
                Assessment of relationship dynamics, potential challenges, and
                long-term harmony.
              </p>
            </div>
            <div>
              <h5 className="font-bold text-black mb-5">
                Astrology Consultation
              </h5>
              <p>
                Personalized sessions focusing on specific life concerns
                (career, finances, health, etc.), including
                <strong> best astrologer in india for consultation</strong>.
              </p>
              <p>
                Actionable advice rooted in astrological wisdom to address
                current challenges.
              </p>
              <p>
                Empowering guidance to help you make informed decisions and take
                control of your life path.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whatsinclude;
