"use client";

import Image from "next/image";
import { AiOutlineArrowRight } from "react-icons/ai";

const Intro = () => {
  return (
    <div>
      <section className="relative py-10 md:py-20 lg:py-24">
        {/* Background Image */}
        <div className="absolute inset-0 z-0">
          <Image
            src="/images/best-astrologer-in-india-for-consultation.jpg"
            alt="Background"
            fill
            className="object-cover"
            quality={75}
            sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
          />
          {/* Opacity Overlay */}
          <div className="absolute inset-0 bg-white/80"></div>
          {/* Adjust opacity as needed */}
        </div>

        {/* Content */}
        <div className="relative z-10 max-w-screen-lg mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 gap-3 md:gap-6 lg:gap-10 items-center justify-between text-center">
            <div>
              <div className="">
                <Image
                  src="/images/om-sign.png"
                  className="object-cover mx-auto"
                  alt="logo"
                  width={50}
                  height={50}
                  quality={75}
                  sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
                />
                <p className="font-semibold text-orange-600 text-base md:text-3xl uppercase my-5">
                  Welcome To
                </p>
              </div>
              <div className="flex gap-10 justify-center">
                <div className="self-center">
                  <Image
                    src="/images/worship.png"
                    className="object-cover"
                    alt="logo"
                    width={50}
                    height={50}
                    quality={75}
                    sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
                  />
                </div>
                <h2 className="font-bold text-black uppercase my-7">
                  SHRI RAAM ASTROLOGER
                </h2>
                <div className="self-center">
                  <Image
                    src="/images/worship.png"
                    className="object-cover"
                    alt="logo"
                    width={50}
                    height={50}
                    quality={75}
                    sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
                  />
                </div>
              </div>

              <p className="text-base md:text-lg lg:text-xl text-neutral-700 font-medium leading-relaxed md:leading-loose lg:leading-loose text-center">
                Shri Raam has dedicated years to mastering the ancient art of
                astrology. His profound knowledge and insightful interpretations
                have allowed him to guide people from all walks of life,
                offering clarity and solutions to their personal and
                professional challenges. His expertise is not just rooted in
                theoretical understanding but in a deep connection with cosmic
                energies, which enables him to provide tailored advice that
                resonates on a spiritual level.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
