"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { FaCheck } from "react-icons/fa";

const Whatsinclude = () => {
  return (
    <div>
      <section className=" bg-red-950 py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-lg mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-16">
            <h2 className="font-bold text-yellow-400 uppercase my-3 leading-normal md:leading-relaxed lg:leading-loose">
              Consultation Fee for Horoscope and Horoscope Matching
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-sretch justify-between text-center">
            <div className="bg-white rounded-xl md:rounded-2xl lg:rounded-3xl shadow-lg max-w-lg mx-auto p-6 md:p-8 ">
              {/* Title */}
              {/* <h3 className="text-center font-bold text-3xl text-black mb-4">
                ECONOMY
              </h3> */}

              {/* Pricing */}
              <p className="text-center text-orange-500 font-bold text-3xl mb-2">
                INR 2000
              </p>

              <hr className="border-t-1 border-orange-500 my-4" />

              <p className="text-center font-bold text-black text-2xl mb-4">
                Duration : 30 Minutes
              </p>
              <p className="text-center text-orange-500 font-bold mb-4">
                (Max 3 Questions on One Horoscope)
              </p>

              <hr className="border-t-1 border-orange-500 my-4" />

              {/* Features List */}
              <ul className="text-black space-y-2 mb-6 list-outside">
                <li className="flex items-center">
                  <FaCheck className="text-orange-500 mr-2" />
                  Consultation for WhatsApp Calling
                </li>
                {/* <li className="flex items-center">
                  <FaCheck className="text-orange-500 mr-2" />
                  No Zoom Recording
                </li> */}
                <li className="flex text-left">
                  <FaCheck className="text-orange-500 mr-2" />
                  3-6 Page PDF with only Astrological Data
                </li>
                {/* <li className="flex text-left">
                  <FaCheck className="text-orange-500 mr-2 w-7 h-7" />
                  Valid for Horoscope Consultation, Horoscope Matching, and
                  Follow Up on Earlier consultation
                </li> */}
                <li className="flex text-left">
                  <FaCheck className="text-orange-500 mr-2 w-9 h-9" />
                  Send Date, Time, Place of Birth, Your 3 Questions and pay
                  after confirmation of appointment timing
                </li>
                <li className="flex text-left">
                  <FaCheck className="text-orange-500 mr-2" />
                  Book appointment in 2 days in advance
                </li>
              </ul>
            </div>
            <div className="bg-white rounded-xl md:rounded-2xl lg:rounded-3xl shadow-lg max-w-lg mx-auto p-6 md:p-8">
              {/* Title */}
              {/* <h3 className="text-center font-bold text-3xl text-black mb-4">
                PREMIUM
              </h3> */}

              {/* Schedule Details */}
              {/* <p className="text-center text-black mb-2 font-bold">
                All Days (5 PM, 7 PM, 9 PM IST)
              </p> */}

              {/* Duration */}
              <p className="text-center text-orange-500 font-bold text-3xl mb-2">
                INR 3000
              </p>
              <hr className="border-t-1 border-orange-500 my-4" />

              <p className="text-center font-bold text-black text-2xl mb-4">
                Duration : 60 Minutes
              </p>

              <hr className="border-t-1 border-orange-500 my-4" />

              {/* Features List */}
              <ul className="text-black space-y-2 mb-6">
                <li className="flex items-center">
                  <FaCheck className="text-orange-500 mr-2" />
                  Consultation for WhatsApp Calling
                </li>
                <li className="flex text-left">
                  <FaCheck className="text-orange-500 mr-2" />
                  3-6 Page PDF with only Astrological Data
                </li>
                <li className="flex text-left">
                  <FaCheck className="text-orange-500 mr-2 w-9 h-9" />
                  Send Date, Time, Place of Birth, Your Questions and pay after
                  confirmation of appointment timing
                </li>
                {/* <li className="flex items-center">
                  <FaCheck className="text-orange-500 mr-2" />
                  Zoom Recording Available
                </li> */}
                {/* <li className="flex items-center">
                  <FaCheck className="text-orange-500 mr-2" />
                  Remote Family Members Can join Zoom call
                </li> */}

                <li className="flex items-center">
                  <FaCheck className="text-orange-500 mr-2" />
                  No Limit Questions
                </li>
                <li className="flex text-left">
                  <FaCheck className="text-orange-500 mr-2" />
                  Book appointment in 2 days in advance
                </li>
              </ul>

              {/* Pricing */}

              <hr className="border-t-1 border-orange-500 my-4" />
              <p className="text-center text-orange-500 font-bold text-2xl mb-2">
                INR 5000
              </p>
              <p className="text-center text-blue-500 mb-6 font-bold">
                Valid for New Horoscope Consultation & New Horoscope Matching
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whatsinclude;
