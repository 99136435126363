"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Moonsign = () => {
  return (
    <div>
      <section className="bg-contain bg-no-repeat bg-bottom py-10 md:py-20 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-16">
            <h2 className="font-bold text-black uppercase my-3">
              Choose your Moon Sign
            </h2>
            <p className="text-base md:text-lg lg:text-xl font-medium">
              Discover what your moon sign can bring for you
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 md:gap-6 items-center justify-between text-center text-lg md:text-xl font-semibold">
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-aries.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>ARIES</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-taurus.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>TAURUS</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-gemini.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>GEMINI</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-cancer.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>CANCER</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-virgo.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>VIRGO</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-leo.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>LEO</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-librs.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>LIBRA</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-scorpio.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>SCORPIO</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-sagittarius.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>SAGITTARIUS</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-pisces.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>PISCES</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-aquarius.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>AQUARIUS</p>
            </div>
            <div>
              <div className="bg-orange-500 w-28 h-28 rounded-full p-4 m-auto mb-3">
                <Image
                  src="/images/ico-capricorn.png"
                  className="object-cover m-auto mb-2"
                  alt="logo"
                  width={90}
                  height={90}
                />
              </div>
              <p>CAPRICORN</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Moonsign;
