"use client";
import Image from "next/image";
import { AiOutlineArrowRight } from "react-icons/ai";

const Servicesec = () => {
  return (
    <div>
      <section className="relative py-10 md:py-20 lg:py-24">
        {/* Background Image */}
        <div className="absolute inset-0 z-0">
          <Image
            src="/images/astrology_bg.jpg"
            alt="Astrology Background"
            fill
            className="object-cover"
          />
        </div>

        {/* Content */}
        <div className="relative z-10 max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-16">
            <h2 className="font-bold text-yellow-400 uppercase my-3">
              Astrology Service
            </h2>
            <p className="text-base md:text-lg lg:text-xl text-white font-medium">
              Discover your future and bring positive change to your life
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 md:gap-6 lg:gap-10 items-stretch text-center">
            {/* First Column */}
            <div className="relative">
              <div className="absolute inset-0 z-0">
                <Image
                  src="/images/astrobg.png"
                  alt="Astrological Background"
                  fill
                  className="object-cover"
                />
              </div>
              <div className="relative z-10 px-6 py-28 md:px-32 md:py-24 lg:px-6 lg:py-24 ">
                <h5 className="font-sans font-bold md:mt-20 lg:mt-0">
                  Shri Raam Astrological Horoscopes
                </h5>
                <p className="text-black font-semibold h-64">
                  Shri Raam offers personalized Vedic Astrological Horoscopes,
                  crafted using the ancient principles of Vedic astrology. By
                  analyzing the precise alignment of planets and stars at the
                  time of a person's birth, he provides comprehensive insights
                  into their life path, strengths, challenges, future
                  opportunities. Services include
                  <strong> vedic astrology horoscope prediction</strong>.
                </p>
                <div className="">
                  <a
                    href="/services"
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 lg:px-10 lg:py-3 rounded-full inline-flex items-center justify-center transition"
                  >
                    Read More
                    <AiOutlineArrowRight className="ml-2" />
                  </a>
                </div>
              </div>
            </div>

            {/* Second Column */}
            <div className="relative">
              <div className="absolute inset-0 z-0">
                <Image
                  src="/images/astrobg.png"
                  alt="Astrological Background"
                  fill
                  className="object-cover"
                />
              </div>
              <div className="relative z-10 px-6 py-28 md:px-32 md:py-24 lg:px-6 lg:py-24 ">
                <h5 className="font-sans font-bold md:mt-20 lg:mt-0">
                  Marriage
                  <br /> Compatibility
                </h5>
                <p className="text-black font-semibold h-64">
                  Importance of harmony in relationships, Shri Raam specializes
                  in Marriage Compatibility analysis through astrological
                  charts. By comparing the horoscopes of two individuals, he
                  evaluates key factors such as emotional connection, mutual
                  support, long-term compatibility. Key services include{" "}
                  <strong>birthdate compatibility for marriage</strong>.
                </p>
                <div className="">
                  <a
                    href="/services"
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 lg:px-10 lg:py-3 rounded-full inline-flex items-center justify-center transition"
                  >
                    Read More
                    <AiOutlineArrowRight className="ml-2" />
                  </a>
                </div>
              </div>
            </div>

            {/* Third Column */}
            <div className="relative">
              <div className="absolute inset-0 z-0">
                <Image
                  src="/images/astrobg.png"
                  alt="Astrological Background"
                  fill
                  className="object-cover"
                />
              </div>
              <div className="relative z-10 px-6 py-28 md:px-32 md:py-24 lg:px-6 lg:py-24 ">
                <h5 className="font-sans font-bold md:mt-20 lg:mt-0">
                  Astrology
                  <br /> Consultation
                </h5>
                <p className="text-black font-semibold h-64">
                  Shri Raam’s Astrology Consultation services are designed to
                  provide personalized guidance on specific life issues or
                  challenges. His consultations offer practical and spiritual
                  advice rooted in astrological science, helping individuals
                  make informed decisions. Popular services include
                  <strong> best astrologer in india for consultation</strong>.
                </p>
                <div className="">
                  <a
                    href="/services"
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 lg:px-10 lg:py-3 rounded-full inline-flex items-center justify-center transition"
                  >
                    Read More
                    <AiOutlineArrowRight className="ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Servicesec;
